import { useState } from "react";
import Hero from "./Hero";
import Scale from "./Scale";
const HeroComponent = () => {
  const [counter, setCounter] = useState(0);
  console.log(counter);
  // debugger
  return (
    <div className="hero-component">
      <Hero counter={counter} setCounter={setCounter} />
      
      <Scale counter={counter} />
    </div>
  );
};
export default HeroComponent;
