import React from "react";

function PhoneComponent() {
  return (
    <div className="phone">
      <p>Phone</p>
      <div className="text" style={{ opacity: "1" }}>
        <p className="txt phonetext" style={{ opacity: "1" }} ><a href="tel:+918217604819" style={{textDecoration:"none",color:"white", opacity:"1"}}>+91 8217604819</a></p>
        <p className="txt phonetext" style={{ opacity: "1" }} ><a href="tel:+918073657275" style={{textDecoration:"none",color:"white", opacity:"1"}}>+91 8073657275</a></p>
      </div>
    </div>
  );
}

export default PhoneComponent;
