import React, { useState } from "react";
import Eyebrow from "../Components/Eyebrow";
import Heading2 from "./Heading-2";
import Blankicon from "../assets/images/blank-icon.svg";
import { expertise_Data } from "../data";
const AreasOfExpertise = () => {
  return (
    <div className="areasOfExpertise row">
      <div className="header col-md-12 col-lg-4">
        <Eyebrow>Why Neoastra ?</Eyebrow>
        <Heading2>
          We provide flexible
          {/* <span className="aqua-color"> intelligence</span>
          <br /> */}
          <br />
          AI platform to meet any business needs
        </Heading2>
      </div>
      <div className="expertise-card-wrapper col-md-12 col-lg-8">
        <div className="row">
        {expertise_Data.map((ele, index) => (
          <div className="expertise-card col-md-12" key={index}>
            <div className="card-icon">
              <img src={Blankicon} className="icon" alt="Expertise" />
              <span className="expertise-image">{ele.indx}</span>
              {/* <img className="expertise-image" src={ele.image} /> */}
            </div>
            <div>
              <div className="title">{ele.title}</div>
              {/* <h5 className="opacity exp-text" >{ele.text}</h5> */}
            </div>
            {/* <div className="card-text">
              <div className="title">{ele.title}</div>
              <img className="expertise-image" src={ele.image} />
            </div> */}
          </div>
        ))}
        </div>
      </div>
    </div>
  );
};
export default AreasOfExpertise;
