import React, { useState } from "react";
import RightArrow from "../assets/images/right-arrow.svg";
import PhoneComponent from "./PhoneComponent";
import EnquiriesComponent from "./EnquiriesComponent";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import navLogo from '../assets/images/neoastralogo.png'
const Footer = (props) => {
  return (
    <motion.div
      className="footer"
      animate={{ position: props.isVisible ? "fixed" : "unset" }}
    >
      <div className="section-wrapper">
        <div className="footer-container">
          <div className="navigation sec">
              <span>
                <h3>Navigation</h3>
                <div className="links-wrapper">
                  {/* <Link
                    to={"/neoastra/neovision"}
                    className="footer-text footer-link"
                  >
                    Neovision
                  </Link>
                  <Link
                    to={"/neoastra/neoliving"}
                    className="footer-text footer-link"
                  >
                    NeoLiving
                  </Link>
                  <Link
                    to={"/neoastra/espirit"}
                    className="footer-text footer-link"
                  >
                    Espirit
                  </Link> */}
                  <Link to={"/about-us"} className="footer-text footer-link">About US</Link>
                  <Link to={"/contact-us"} className="footer-text footer-link">
                    Contact Us
                  </Link>
                  <Link to={"/career"} className="footer-text footer-link">
                    Career
                  </Link>
                </div>
              </span>
            </div>
          <div className="address sec">
            <span>
                <h3>Address</h3>
                <p className="txt">Smartworks Vaishnavi Tech Park Bellandur Gate, Sarjapur Main Rd, Ambalipura, Bengaluru, Karnataka 560102</p>
            </span>
          </div>
          <div className="Ft-title sec">
            <div>
              <div>
              <img className="navLogo" src={navLogo} alt="" />
              </div>
              <div className="quate">
                Together with AI.
              </div>
            </div>
          </div>
          
          <div className="solutions sec">
            <span>
              <h3>Solutions</h3>
              <div className="links-wrapper">
                
                <Link to={"/neovision"} className="footer-text footer-link">
                  NeoVision
                </Link>
                <Link to={"/neoliving"} className="footer-text footer-link">
                  NeoLiving
                </Link>
                <Link to={"/"} className="footer-text footer-link">
                  NeoRetail
                </Link>
              </div>
            </span>
          </div>

          <div className="contWrap">
            <div className="phone sec">
              <span>
                <h3>Phone</h3>
                <span className="phWrap">
                  <p className="txt" ><a href="tel:+918217604819" style={{textDecoration:"none",color:"white"}}>+91 8217604819</a></p>
                  <p className="txt" ><a href="tel:+918073657275" style={{textDecoration:"none",color:"white"}}>+91 8073657275</a></p>

                </span>
                
              </span>
            </div>
            <div className="enquiry sec">
              <span>
                <h3>Write to Us</h3>
                <p className="txt"><a href = "mailto: info@neoastra.com" style={{textDecoration:"none",color:"white"}}>info@neoastra.com</a></p>
              </span>
            </div>
          </div>

          

          
          <div className="follow sec">
            <h3>Follow us</h3>
            <div className="links-wrapper">
              <h5 className="footer-text ">
                <a
                  className="footer-socialMedia "
                  href="https://www.facebook.com/NeoastraT"
                >
                  Facebook
                </a>
                <a
                  className="footer-socialMedia "
                  href=
                    "https://www.linkedin.com/company/neoastra/?originalSubdomain=in"
                >
                  LinkedIn
                </a>
                  
                <a className="footer-socialMedia ">Twitter</a>
                <a className="footer-socialMedia ">Instagram</a>
              </h5>
            </div>
          </div>
          
        </div>
        {/* <div className="section-3">
            <p className="header-title title-foot">
              neo<span className="aqua-color">astra</span>
            </p>
            
            <a className="quote-link">
              Get Quote <img src={RightArrow} alt="Get Quote" />
            </a>
          </div> */}
      </div>

      <div className="section-4">
        Copyright © 2022 Neoastra, All rights reserved. Powered by Wizroots
      </div>
    </motion.div>
  );
};
export default Footer;
