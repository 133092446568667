import previousIcon from "../assets/images/previous.svg";
import nextIcon from "../assets/images/next.svg";
import horizontalBar from "../assets/images/horizontalbar.svg";
const Controls = (props) => {
  return (
    <div className="controls">
      <img
        src={previousIcon}
        className="previous-icon"
        onClick={props.prevClickHandler}
        alt="Previous"
      ></img>
      <div className=" horizontal-bar">
        <div className="progress"></div>
      </div>
      <img
        src={nextIcon}
        className="next-icon"
        onClick={props.nextClickHandler}
        alt="Next"
      ></img>
    </div>
  );
};
export default Controls;
