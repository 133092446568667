import Eyebrow from "./Eyebrow";
import Cards from "./Cards";
import Heading2 from "./Heading-2";
import { products_data } from "../data";
import grid4 from "../assets/images/Repeat Grid 4.svg";
const Products = () => {
  return (
    <div className="products">
      <Eyebrow>Solutions</Eyebrow>

      <Heading2>
        <span className="aqua-color">Super intelligent</span>
        <br />
        AI Platform
      </Heading2>
      <Cards data={products_data} />
    </div>
  );
};
export default Products;
