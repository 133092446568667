import Eyebrow from "./Eyebrow";
import Heading2 from "./Heading-2";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import Adobe from "../assets/images/Adobe.png";
import Disney from "../assets/images/disney.png";
import VRBengaluru from "../assets/images/vr-bengaluru.png";
import Jio from "../assets/images/jio.png";

const SupportedBy = () => {
  return (
    <div className="supported-by container">
      <Eyebrow>Partners</Eyebrow>
      <Heading2 className="aqua-color">They trust us</Heading2>
      <div className="supported-by-list">
        <Swiper
          slidesPerView={"4"}
          navigation={true}
          modules={[Navigation]}
          spaceBetween={8}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={Adobe} alt="adobe" className="supported-by-list-item" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Disney} alt="adobe" className="supported-by-list-item" />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={VRBengaluru}
              alt="adobe"
              className="supported-by-list-item"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Jio} alt="adobe" className="supported-by-list-item" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Adobe} alt="adobe" className="supported-by-list-item" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Disney} alt="adobe" className="supported-by-list-item" />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={VRBengaluru}
              alt="adobe"
              className="supported-by-list-item"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Jio} alt="adobe" className="supported-by-list-item" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
export default SupportedBy;
