import { useEffect } from "react";

const Scale = (props) => {
  // console.log(props);
  // useEffect(() => {
  //   if (props.counter != undefined) {
  //     document
  //       .querySelectorAll(".hero-component .scale-item")
  //       .forEach((e) => e.classList.remove("active"));
  //     document
  //       .querySelectorAll(".hero-component .scale-item")
  //       [props.counter].classList.add("active");
  //   }
  //   if (props.processCounter != undefined) {
  //     document
  //       .querySelectorAll(".process-component .scale-item")
  //       .forEach((e) => e.classList.remove("active"));
  //     document
  //       .querySelectorAll(".process-component .scale-item")
  //       [props.processCounter].classList.add("active");
  //   }
  // }, [props.counter, props.processCounter]);
  function headerPrevCount(num){
    
    if(num == 0){
      return 3
    }
    else{
      return num
    }
  }
  function prevCount(num){
    
    if(num == 0){
      return 7
    }
    else{
      return num
    }
  }
  function currentCount(num){
    // debugger
    if(!num){
      return 1
    }
    return num+1
  }
  function nextCount(num){
    if(num == 6 ){ 
      return 1
    }
    else{
      return num+2
    }
  }
  function HeaderNextCount(num){
   
    if(num == 2 ){ 
      return 1
    }
    else{
      return num+2
    }
  }
  return (
    <div className="scale light-grey-color">
      <div className="scale-item">
        <div className="gradient gradient-up"></div>
        
        {props.counter||props.counter==0?headerPrevCount(props.counter):prevCount(props.processCounter)}
        
        <div className="gradient gradient-down"></div>
      </div>
      <div className="scale-item active">
        <div className="gradient gradient-up"></div>
        {props.counter?currentCount(props.counter):currentCount(props.processCounter)}
        <div className="gradient gradient-down"></div>
      </div>
      <div className="scale-item">
        <div className="gradient gradient-up"></div>
        {props.counter||props.counter==0?HeaderNextCount(props.counter):nextCount(props.processCounter)}
        <div className="gradient gradient-down"></div>
      </div>
    </div>
  );
};
export default Scale;
