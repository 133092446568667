import Image from "../assets/images/Group 111.png";
const HeroImage = () => {
  return (
    <div className="hero-image class-1 col-md-6">
      <div className="image-container" />
      <img className="foreground-image brain" src={Image} alt="Hero" />
    </div>
  );
};
export default HeroImage;
