import { data } from "../data";
import Heading1 from "./Heading-1";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import { useNavigate } from "react-router-dom";
const HeroText = (props) => {
  const dataItem = data[props.counter];
  const indexOfSpace = dataItem.title.indexOf(" ");
  const navigate = useNavigate();
  // const prevClickHandler = () => {
  //   if (props.counter === 0) {
  //     props.setCounter(2);
  //   } else props.setCounter((prev) => prev - 1);
  // };
  // const nextClickHandler = () => {
  //   if (props.counter === 2) {
  //     console.log("if");
  //     props.setCounter(0);
  //   } else props.setCounter((prev) => prev + 1);
  // };
  return (
    <div className="hero-text">
      {/* <Controls
        // prevClickHandler={prevClickHandler}
        // nextClickHandler={nextClickHandler}
      /> */}
      <Heading1>
        <span className="aqua-color">{dataItem.title.split(" ")[0]}</span>{" "}
        {dataItem.title.split(" ")[1] + " " + dataItem.title.split(" ")[2]}
        <br></br>
        {dataItem.title.substring(
          indexOfSpace +
            1 +
            (dataItem.title.split(" ")[1] + " " + dataItem.title.split(" ")[2])
              .length
        )}
      </Heading1>
      <h5 className="light-grey-color">{dataItem.description} </h5>
      <div className="buttons">
        <PrimaryButton className="button">discover More</PrimaryButton>
        <SecondaryButton
          className="button"
          onClick={() => navigate("/contact-us")}
        >
          contact us
        </SecondaryButton>
      </div>
    </div>
  );
};
export default HeroText;
