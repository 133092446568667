const FeatureCard = (props) => {
  return (
    <div className="product-feature col-md-4">
      <div className="product-feature_content">
        <div className="feature_heading">{props.feature.feature_title}</div>
        {
          props.feature.feature_text?
          props.feature.feature_text.map(d=>{
            return (
              <div className="feature_text text">{d}</div>
            )
          })

          :""
        }
        {/* <div className="feature_text text">{props.feature.feature_text}</div> */}
      </div>
    </div>
  );
};
export default FeatureCard;
