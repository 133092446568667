import Card from "./Card";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const Cards = (props) => {
  return (
    <>
      <div className="cards cards-desktop row ">
        {/* <Card data={props.data[0]} />
        <Card data={props.data[1]} />
        <Card data={props.data[2]} />
        <Card data={props.data[3]} />
        <Card data={props.data[4]} />
        <Card data={props.data[5]} /> */}
        {props.data.map(d=>{
          return(
            <Card data={d}/>
          )
        })}
      </div>
      <div className="cards cards-mobile">
        <Swiper
          dir={props.rtl}
          slidesPerView={"auto"}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
          
        >
          {props.data.map(d=>{
          return(
            <SwiperSlide>
            <Card data={d} />
          </SwiperSlide>
          )
        })}
        </Swiper>
      </div>
    </>
  );
};
export default Cards;
