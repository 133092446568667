import { useState } from "react";
import Eyebrow from "../Components/Eyebrow";
import Accordion from "../Components/Accordion";
import CareerImg from "../assets/images/Asset 3.png";
import About from "../Components/About";
import {
  career_page_accordian_data,
  about_us_page,
  career_text,
} from "../data";
import Heading2 from "../Components/Heading-2";

const Career = () => {
  const [isOpen, setOpen] = useState(false);
  const [accordionIndex, setIndex] = useState(0);
  const toggleAccordion = (index) => {
    setOpen(!isOpen);
    setIndex(index);
  };
  window.onload = function () {
    var icon1 = document.querySelector(".icon1");
    var icon2 = document.querySelector(".icon2");
    var icon3 = document.querySelector(".icon3");
    var icon4 = document.querySelector(".icon4");
    var icon5 = document.querySelector(".icon5");
    icon1.addEventListener("click", (e) => changeIcon(e, icon1));
    icon2.addEventListener("click", (e) => changeIcon(e, icon2));
    icon3.addEventListener("click", (e) => changeIcon(e, icon3));
    icon4.addEventListener("click", (e) => changeIcon(e, icon4));
    icon5.addEventListener("click", (e) => changeIcon(e, icon5));
  };
  const changeIcon = (e, icon) => {
    var centerIcon = document.querySelector(".bigIcon");
    centerIcon.insertAdjacentElement("afterend", centerIcon.childNodes[0]);
    centerIcon.appendChild(icon);
    icon.setAttribute("transform", "translate(0,120)");
  };
  return (
    <div className="career-page container">
      <div className="section-1">
        <div className="career-page_left-content">
          <Eyebrow>Careers</Eyebrow>
          <div className="heading-2">Join us and lets Grow together</div>
          <p className="career-text text" style={{marginBottom:"0px"}}>{career_text[0]}</p>
          <p className="career-text text">{career_text[1]}</p>
        </div>
        <div className="career-page_right-content class-1">
          <div className="image-container"></div>
          <img
            src={CareerImg}
            className="foreground-image hand"
            alt="Careers"
          />
        </div>
      </div>
      <div className="section-2">
        <Eyebrow>Open positions</Eyebrow>
        <Heading2>
          If you have
          <br /> <span className="aqua-color">what</span> it takes
        </Heading2>
        <div className="accordion-div">
          <Accordion accordionData={career_page_accordian_data} />
        </div>
      </div>
      <div className="section-3">
        <About
          about_eyebrow={about_us_page.about_eyebrow_variation2}
          about_title={about_us_page.about_title}
          about_text={about_us_page.about_text}
        />
      </div>
    </div>
  );
};
export default Career;
