import SocialMedia from "./SocialMedia";
import { team_details } from "../data";
import Eyebrow from "./Eyebrow";
import Heading2 from "./Heading-2";
// import Member1 from "../assets/images/Person1.jpg";
// import Member2 from "../assets/images/Person2.jpg";
// import Member3 from "../assets/images/Person3.jpg";

const Team = () => {
  return (
    <div className="team-wrapper">
      <Eyebrow>Team </Eyebrow>
      <Heading2>
        When you need a
        <span className="aqua-color"> talented</span>, <br/> <span className="aqua-color"> fast</span> and <span className="aqua-color"> powerful</span> team
        <br />
      </Heading2>
      <div className="team row">
        {team_details.map((member, index) => {
          return (
            <div className="team-card col-md-4" key={index}>
              <div className="team-card-header"></div>
              <img
                className="team-card-img"
                src={member.member_image.members[index]}
                alt={member.member_name}
              ></img>
              <div className="team-card-body">
                <h6>{member.member_name}</h6>
                <div className="pos">Co founder</div>
                <h5 className="light-grey-color co-founder-card-text">
                  {member.member_description}
                </h5>
                <SocialMedia linkedin={member.linkedIn} head={false} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Team;
