import polygon from "../assets/images/polygon.svg";
import playButton from "../assets/images/play.svg";


import { useEffect, useRef } from "react";

import YouTube from "react-youtube";


const Video = (props) => {
  const videoRef0 = useRef("");
  const videoRef1 = useRef("");
  const videoRef2 = useRef("");
  // useEffect =
  //   (() => {
  //     document.querySelector(".polygon").style.display = "block";
  //     document.querySelector(".play").style.display = "block";
  //   },
  //   [props.counter]);
  const playVideo1Handler = (e) => {
    videoRef0.current.play();
    document.querySelector(".polygon").style.display = "none";
    document.querySelector(".play").style.display = "none";
  };
  const playVideo2Handler = (e) => {
    videoRef1.current.play();
    document.querySelector(".polygon").style.display = "none";
    document.querySelector(".play").style.display = "none";
  };
  const playVideo3Handler = (e) => {
    videoRef2.current.play();
    document.querySelector(".polygon").style.display = "none";
    document.querySelector(".play").style.display = "none";
  };

  const videooptions ={
    height: "100%",
    width: "100%",
  }
  const videos = ['xUwEX1Puatk','0onNnNzDgSc','PHqPUnFPjmY','Fqn7BIi9CHc','BBC4pub165M','6TmKmJMurkA','TTpO2-gVwWc']
  return (
    <div className="video-component">
      

      <YouTube className="fullWidthHeight" videoId={videos[props.counter]} opts={videooptions} onPlay = {props.resettimer} />


      {/* <img
        src={polygon}
        className="polygon"
        onClick={() => {
          {
            props.counter == 0 && playVideo1Handler();
          }
          {
            props.counter == 1 && playVideo2Handler();
          }
          {
            props.counter == 2 && playVideo3Handler();
          }
        }}
      /> */}
      <img src={playButton} className="play" />
      {/* <div className="video-description">
        {props.counter == 0 && <span className="video-desc-text">Neovision</span>}
        {props.counter == 1 && <span className="video-desc-text">Neoliving</span>}
        {props.counter == 2 && <span className="video-desc-text">Espirit</span>}
        <div className="video-desc-logo">
          neo<span className="aqua-color">astra</span>

        </div>
      </div> */}
    </div>
  );
};

export default Video;
