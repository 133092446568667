import Eyebrow from "./Eyebrow";
import waweImg from "../assets/images/asdasdasd.png";
import FormContent from "./FormContent";
import Careerform from "./Careerform";
const ApplyNowForm = (props) => {
  return (
    <>
      <div className="careerform-container">
        {/* <Eyebrow>{props.eyebrow_text}</Eyebrow> */}
        <p className="subhead">Apply for this position</p>
        <h3>Join</h3>
        <h3>the <span className="prime-color">force</span></h3>
        {/* <FormContent /> */}
        <Careerform/>
      </div>
      <img src={waweImg} className="waweImg" />
    </>
  );
};
export default ApplyNowForm;
