import About from "../Components/About";
import { about_us_page } from "../data";
import aboutus_hero from "../assets/images/Group 253.png";
import Eyebrow from "../Components/Eyebrow";
import Heading2 from "../Components/Heading-2";
import about_us_bg from "../assets/images/asdasdasd.png";
import whiteWavemob from "../assets/images/asdasdasd.png";
import Team from "../Components/Team";
import grid4 from "../assets/images/Repeat Grid 4.svg";

const AboutUs = (props) => {
  return (
    <>
      <div className=" about-us-page container">
        {/* <Header toggleShowMenu={props.toggleShowMenu} /> */}
        <div className="about-us">
          <div className="col about-us-hero">
            <div className="about-us-hero-img">
              <img
                src={aboutus_hero}
                className="foreground-image brain-2"
              ></img>
            </div>
            <div className="about-us-hero-text">
              <Eyebrow>About Us</Eyebrow>
              <Heading2>
                Empowering
                <span className="aqua-color"> people</span> by
                <br />
                keeping them well
              </Heading2>
              <h5 className="light-grey-color">
              We are an Enterprise AI Company. We are a team of experienced software and hardware geeks, designers, innovators doing passionate things to run your business and keep live your dreams. The goal is to provide innovative solutions to everyone by leveraging the benefits that AI, Deep learning, and Internet of Things paradigm has to offer. Neoastra’s flagship solutions offer the power of AI and Machine Learning to every problem that businesses and customers face in their operations. 
              <br/>At Neoastra, we couple augmented intelligence with image analytics, video analytics, and emerging technologies with IOT to help businesses deliver immersive customer experiences and outperform the competition.
              </h5>
            </div>
          </div>
          <div className="about-us-team grid-4">
            <Team />
            <img
              src={grid4}
              className="grid-4-desktop grid-4-bg-img"
              alt=""
            ></img>
          </div>
        </div>
      </div>
      <div className="about-us-section">
        <div className="blue-wave">
          <img src={about_us_bg} className="blue-wave-desktop" alt=""></img>
          {/* <img src={whiteWavemob} className="blue-wave-mob" alt=""></img> */}
          <About
            about_eyebrow={about_us_page.about_eyebrow_variation2}
            about_title={about_us_page.about_title}
            about_text={about_us_page.about_text}
            about_footer={about_us_page.about_footer}
          />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
