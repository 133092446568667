import { Link } from "react-router-dom";
import navLogo from '../assets/images/neoastralogo.png'
const Title = () => {
  return (
    <Link to="/" className="link-text">
      <img className="navLogo" src={navLogo} alt="" />
      {/* neo<span className="aqua-color">astra</span> */}
    </Link>
  );
};
export default Title;
