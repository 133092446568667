import NeovisionFace from "./assets/images/head.png";
import NeovisionSecondImage from "./assets/images/Image 5.png";
import Member1 from "./assets/images/Person1.jpg";
import Member2 from "./assets/images/Person2.jpg";
import Member3 from "./assets/images/Person3.jpg";
import MediaOTT from "./assets/images/charity.svg";
import Surveillance from "./assets/images/growth.svg";
import Media from "./assets/images/shopping-cart.svg";
import Agriculture from "./assets/images/diploma.svg";
import FleetManagement from "./assets/images/research.svg";
import Automative from "./assets/images/car-parts.svg";
import video1 from "./assets/videos/video1.mp4";
import video2 from "./assets/videos/video2.mp4";
import video3 from "./assets/videos/video3.mp4";
import retailImg from './assets/images/whatweoffer/shopping-store.png';
import mediaImg from './assets/images/whatweoffer/media.png';
import mediaottImg from './assets/images/whatweoffer/mediaott.png';
import sportsImg from './assets/images/whatweoffer/sports.png';
import automotiveImg from './assets/images/whatweoffer/electric-vehicle.png';
import surveillanceImg from './assets/images/whatweoffer/surveillance.png';

import facedetectionImg from "./assets/images/capabilities/facedetection.svg"
import facerecognitionImg from "./assets/images/capabilities/facerecognition.svg"
import agedetectionImg from "./assets/images/capabilities/agedetection.png"
import objectImgImg from "./assets/images/capabilities/objectImg.svg"
import genderdetectionImg from "./assets/images/capabilities/genderdetection.svg"
import landscapImg from "./assets/images/capabilities/landscap.svg"
import motiondetectionImg from "./assets/images/capabilities/motiondetection.svg"
import posedetectionImg from "./assets/images/capabilities/posedetection.svg"
import faceaugmentationImg from "./assets/images/capabilities/faceaugmentation.svg"
import guesturedetectionImg from "./assets/images/capabilities/guesturedetection.svg"
import emotionrecognitionImg from "./assets/images/capabilities/emotionRecognition.svg"
import media from "./assets/images/capabilities/media.svg"
import cert1 from './assets/images/certificates/cert1.png';
import cert2 from './assets/images/certificates/cert2.png';
import cert3 from './assets/images/certificates/cert3.png';

import neoliving from './assets/images/neolivingImg.svg'
import neoretail from './assets/images/neoretailImg.svg'
import neovision from './assets/images/eye-icon.svg'

import Img1 from './assets/images/counts/1.png' 
import Img2 from './assets/images/counts/2.png' 
import Img3 from './assets/images/counts/3.png' 
import Img4 from './assets/images/counts/4.png' 
import Img5 from './assets/images/counts/5.png' 

const members = [Member1, Member2, Member3];
const expertise_images = [
  retailImg,
  mediaImg,
  mediaottImg,
  sportsImg,
  automotiveImg,
  surveillanceImg,
];
const videos = [video1, video2, video3];
export const data = [
  {
    title: "AI that powers Enterprises",
    description:
      "At neoastra, we strive to empower people and organizations to have a better and secured life by providing solutions driven by AI, connected intelligence, passion and  simplicity.",
  },
  {
    title: "AI that powers Enterprises",
    description:
      "An Intelligent Security 360 product company that empowers security organizations and enterprises to deliver safe and secure environment for their employees and customers.",
  },
  {
    title: "AI that powers Enterprises",
    description:
      "We strive to develop products that will enhance people’s lives through technical innovation, leadership and partnerships.",
  },
];
export const certificates = [
  cert1,cert2,cert3
]
export const products_data = [
  {
    title: "NeoVision",
    description:
      "NeoVision is a suite of AI software which harness the powers of machine learning and artificial intelligence to transform your camera data into actionable intelligence. It is  integrated to track crowd movements, detect physical objects, motion tracking, object count, remote access, and abnormal event triggers.",
    link_text: "more on NeoVision",
    logo:neovision
  },
  {
    title: "NeoLiving",
    description:
      "Neoliving is AI-powered platform for devices and systems to manage and control home appliances, security systems, and entertainment systems. AI powered smart devices are network-based control solution that incorporates communication between various switches and loads. The features include send messages from any device to any device at enterprise.",
    link_text: "more on NeoLiving",
    logo:neoliving
  },
  {
    title: "NeoRetail",
    description:
      "AI powered Smart Retail Platform empowers retailers to transform the camera data into actionable information for data-driven decisions and adapt for best in-store sales performance and loyalty. Retailers are able to understand patterns and enhance decision making, based on customer behaviour.",
    link_text: "more on NeoRetail",
    logo:neoretail
  },
];
export const accordion_data = [
  // {
  //   title: "Enterprise AI - Video Analytics",
  //   children:
  //     "Video Analytics Surveillance | Geo-spatial Analytics | Intelligent Workflows | Alert Notifications ",
  // },
  // {
  //   title: "Face and Object Tracking and Image Analytics",
  //   children:
  //     "Face Recognition and Tagging | Image Analytics and Classification | Object Recognition and Classification  ",
  // },
  // {
  //   title: "Face and Object Tracking and Image Analytics (R&D)",
  //   children: "Sentiment Analytics | Emotion Analytics | Behavioral Analytics",
  // },
  // {
  //   title: "People Tracking and Analyticss",
  //   children:
  //     "People Counting and Tracking | Geo-spatial Analytics | In-store Analytics | Heat Map and Footfall Analysis",
  // },
  // {
  //   title: "People Tracking and Analytics (R&D)",
  //   children:
  //     "Queue Management | Vehicle Management | Vehicle Number-plate Detection",
  // },
  // {
  //   title: "Mobile AI Applications",
  //   children: "Android | iOS  ",
  // },
  {
    img:facedetectionImg,
    title:"Face Detection"
  },
  {
    img:facerecognitionImg,
    title:"Face Recognition"
  },
  {
    img:agedetectionImg,
    title:"Age Detection"
  },
  {
    img:objectImgImg,
    title:"Object Recognition"
  },
  {
    img:genderdetectionImg,
    title:"Gender Detection"
  },
  {
    img:landscapImg,
    title:"Landscape Identification"
  },
  {
    img:motiondetectionImg,
    title:"Human Action Analysis"
  },
  {
    img:posedetectionImg,
    title:"Pose Estimation"
  },
  {
    img:faceaugmentationImg,
    title:"Face Augmentation"
  },
  {
    img:guesturedetectionImg,
    title:"Gesture Recognition"
  },
  {
    img:emotionrecognitionImg,
    title:"Emotion Recognition"
  },
  {
    img:media,
    title:"Media Search (image,video)"
  },
];
export const expertise_Data = [
  {
    title: "Device and OS agnostic platform",
    indx: "1",
  },
  {
    title: "Low memory, low CPU/GPU footprint",
    indx: "2",
  },
  {
    title: "Pluggable AI training and inference platform",
    indx: "3",
  },
  {
    title: "Edge, cloudand hybrid deployment capable",
    indx: "4",
  },
  {
    title: "Customization data augmentation and annotation platform",
    indx: "5",
  },
];
export const device_data = [
  {
    title: "Esprit",
    description:
      "ESPRIT is an AI Powered video and image analyzer platform to provide contextual, real-time and memorable customer experience. It is a custom suite of intelligent algorithms based on ML and AI to identify content and provide visual notifications based on on-screen content. ESPRIT utilizes the information in the digital media to provide useful insights to individuals, businesses, and end users.",
    link_text: "Learn more",
    logo:Img1
  },
  {
    title: "NeoKart",
    description:
      "Neokart is AI powered smart shopping cart to provide an intuitive and innovative customer experience in physical retail store.The NeoKart will scan the bar code of products or items are identified by the camera when dropped to the cat. Customers at retail stores can avoid the long queues during checkout and just walk out by paying via payment gateway.",
    link_text: "Learn more",
    logo:Img2
  },
  {
    title: "NeoSports",
    description:
      "NeoSports is an AI powered analytics platform which provides live-tracking performances, perfecting athletic movements. Platform will analysis sports performance, where AI is used to track player movements, analyze game footage, and provide insights and recommendations to coaches and players. The platform does pose detection, to analyze images or video footage of athletes and detect their poses, movements, and gestures.",
    link_text: "Learn more",
    logo:Img3
  },
  {
    title: "NeoAuto",
    description:
      "NeoAuto powered by Artificial Intelligence is a suite of software integrated for automotive and traffic analytics. The features include driver analytics, smart parking, traffic Monitoring and analysis and seizure detection. The assistance systems will provide AI-based monitoring and application controls.",
    link_text: "Learn more",
    logo:Img4
  },
  {
    title: "Smart Retail",
    description:
      "Our Smart retail solution transforms the camera data and user interactions into actionable intelligence for better decision-making for retailers to enhance customer experience. It  provide Hyper Personal Product recommendation in a retail store to better personalize their offering to consumers, for example via recommendation engines showing certain kinds of styles and garments based on the user’s previous purchase. ",
    link_text: "Learn more",
    logo:Img5
  }
];
export const process_data = [
  {
    title: "Neovision",
    description:
      "NeoVision is a suite of AI software integrated to track crowd movements and detect physical objects.",
    process_video: videos[0],
    link_text: "Learn more",
  },
  {
    title: "Neoliving",
    description:
      "NeoLiving is AI powered smart devices that are network-based control solution.",
    process_video: videos[1],
    link_text: "Learn more",
  },
  {
    title: "Espirit",
    description:
      "ESPRIT is a custom suite of intelligent algorithms based on ML and AI to identify content and provide visual notifications based on on-screen content.",
    process_video: videos[2],
    link_text: "Learn more",
  },
  {
    title: "Neovision",
    description:
      "NeoVision is a suite of AI software integrated to track crowd movements and detect physical objects.",
    process_video: videos[3],
    link_text: "Learn more",
  },
  {
    title: "Neoliving",
    description:
      "NeoLiving is AI powered smart devices that are network-based control solution.",
    process_video: videos[4],
    link_text: "Learn more",
  },
  {
    title: "Espirit",
    description:
      "ESPRIT is a custom suite of intelligent algorithms based on ML and AI to identify content and provide visual notifications based on on-screen content.",
    process_video: videos[5],
    link_text: "Learn more",
  },
  {
    title: "Espirit",
    description:
      "ESPRIT is a custom suite of intelligent algorithms based on ML and AI to identify content and provide visual notifications based on on-screen content.",
    process_video: videos[6],
    link_text: "Learn more",
  },
];
export const menu_data = [
  { name: "home", link: "/" },
  {
    name: "solutions",
    icon: "true",
    children: [
      { face_text: "neovision", link: "neovision" },
      { face_text: "neoliving", link: "neoliving" },
      { face_text: "neoretail", link: "neoretail" },
    ],
  },
  {
    name: "products",
    icon: "true",
    children: [
      { face_text: "Esprit", link: "" },
      { face_text: "NeoKart", link: "" },
      { face_text: "NeoSports", link: "" },
      { face_text: "NeoAuto", link: "" },
      { face_text: "Smart Retail", link: "" },
    ],
  },
  { name: "about us", link: "/about-us" },
  { name: "contact us", link: "/contact-us" },
  { name: "career", link: "/career" },
];
export const about_us_page = {
  about_eyebrow: "About Us",
  about_title: {
    __html: "Why choose <span class = 'aqua-color'><br/>Neoastra</span> ?",
  },
  about_eyebrow_variation2: "Get in touch",
  about_text: {
    __html:
      "We have deep expertise in building Computer Vision solutions for image classification, age and gender determination, emotion detection, facial recognition, object detection & segmentation, and other use cases. <br/><br/>We offer on-premise computer vision solutions which increases data security and performance speeds for our partners. Our platform-agnostic solutions are trained specifically for each deployment and work in a variety of environments. We enhance the safety and security of your workplace through real-time insights from our Neoastra NeoVision video analytics engine.",
  },
  contactUs: true,
  about_footer: "CONTACT US",
};
export const neovision_page_data = [
  {
    hero_image: { NeovisionFace },
    hero_title: "Neovision",
    hero_text:
      "NeoVision is a suite of AI software integrated to track crowd movements and detect physical objects.",
    about_text: {
      __html:
        "NeoVision is a suite of AI software integrated to track crowd movements and detect physical objects. Motion tracking, object count, remote access and abnormal event triggers are some of the key highlights of this offering. Its features include cloud based Processing, multiple devices and mobile application to control and for the alert notifications.<br/><br/> Enhance the safety and security of your enterprise through real-time insights from our AI-powered video analytics engine<br/><br/> Our tested and proven solution scales for small, medium and large enterprises ranging from 4 cameras to 150+ cameras.",
    },
    second_image: { NeovisionSecondImage },
    eyebrow_text: "Features",
    eyebrow_title: {
      __html:
        "How <span class = 'aqua-color'>neoastra</span><br> Marks its spot",
    },
    feature_list: [
      {
        feature_title: "Enterprise AI - Video Analytics",
        feature_text:
          ["Video Analytics Surveillance Geo-spatial Analytics Intelligent Workflows Alert Notifications ."],
      },
      {
        feature_title: "Face & Object Tracking",
        feature_text:
          ["- Face Recognition and Tagging", "- Image Analytics and Classification" , " - Object Recognition and Classification" , " - Sentiment Analytics" , " - Emotion Analytics" , " - Behavioral Analytics."],
      },
      {
        feature_title: "Analytics",
        feature_text:
          ["- People Counting" , " - People Tracking" , " - Heat Map" , " - Footfall Analysis "],
      },
    ],
  },
];
export const neoliving_page_data = [
  {
    hero_image: { NeovisionFace },
    hero_title: "Neovision",
    hero_text:
      "NeoVision is a suite of AI software integrated to track crowd movements and detect physical objects.",
    about_text: {
      __html:
        "NeoVision is a suite of AI software integrated to track crowd movements and detect physical objects. Motion tracking, object count, remote access and abnormal event triggers are some of the key highlights of this offering. Its features include cloud based Processing, multiple devices and mobile application to control and for the alert notifications.<br/><br/> Enhance the safety and security of your enterprise through real-time insights from our AI-powered video analytics engine<br/><br/> Our tested and proven solution scales for small, medium and large enterprises ranging from 4 cameras to 150+ cameras.",
    },
    second_image: { NeovisionSecondImage },
    eyebrow_text: "Features",
    eyebrow_title: {
      __html:
        "How <span class = 'aqua-color'>neoastra</span><br> Marks its spot",
    },
    feature_list: [
      {
        feature_title: "Enterprise AI - Video Analytics",
        feature_text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus.",
      },
      {
        feature_title: "Enterprise AI - Video Analytics",
        feature_text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus.",
      },
      {
        feature_title: "Enterprise AI - Video Analytics",
        feature_text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus.",
      },
      {
        feature_title: "Enterprise AI - Video Analytics",
        feature_text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus.",
      },
    ],
  },
];
export const esprit_page_data = [
  {
    hero_image: { NeovisionFace },
    hero_title: "Neovision",
    hero_text:
      "NeoVision is a suite of AI software integrated to track crowd movements and detect physical objects.",
    about_text: {
      __html:
        "NeoVision is a suite of AI software integrated to track crowd movements and detect physical objects. Motion tracking, object count, remote access and abnormal event triggers are some of the key highlights of this offering. Its features include cloud based Processing, multiple devices and mobile application to control and for the alert notifications.<br/><br/> Enhance the safety and security of your enterprise through real-time insights from our AI-powered video analytics engine<br/><br/> Our tested and proven solution scales for small, medium and large enterprises ranging from 4 cameras to 150+ cameras.",
    },
    second_image: { NeovisionSecondImage },
    eyebrow_text: "Features",
    eyebrow_title: {
      __html:
        "How <span class = 'aqua-color'>neoastra</span><br> Marks its spot",
    },
    feature_list: [
      {
        feature_title: "Enterprise AI - Video Analytics",
        feature_text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus.",
      },
      {
        feature_title: "Enterprise AI - Video Analytics",
        feature_text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus.",
      },
      {
        feature_title: "Enterprise AI - Video Analytics",
        feature_text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus.",
      },
      {
        feature_title: "Enterprise AI - Video Analytics",
        feature_text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus.",
      },
    ],
  },
];
export const career_text =
  ["Building the future of AI. We’re working to find new and better ways to help businesses succeed, and we’re looking for people like you to help shape tomorrow at Neoastra.", "We remain committed to an inclusive and diverse workplace, where people can be who they are and be their best, professionally, and personally."];
export const career_page_accordian_data = [
  {
    id: "001",
    title: "Senior Software Engineer (Windows Applications) ",
    children:
      "We are looking for a .Net Developer with having a good knowledge of .Net, C#, WPF to join the Software Development team who possesses a passion for next generation technologies.",
    buttons: ["apply now", "more details"],
  },
  {
    id: "002",
    title: "Software Engineer (Android)",
    children:
      "We are looking for a Mobile Application Developer who possesses a passion for mobile technologies and will work with next generation technologies.",
    buttons: ["apply now", "more details"],
  },
  {
    id: "003",
    title: "Software Engineer (AI - ML)",
    children:
      "We are looking for an AI/ML developer with having a good knowledge of C++ to join the Software Development team in our organization. A candidate must have experience in designing and implementing Machine Learning algorithms. Knowledge of Python is required.",
    buttons: ["apply now", "more details"],
  },
  {
    id: "004",
    title: "Software Engineer (iOS)",
    children:
      "We are looking for an iOS/OSX developer who possesses a passion for mobile technologies having a good knowledge of Swift, ObjC, and cocoa pods to join the Software Development team in our organization.",
    buttons: ["apply now", "more details"],
  },
];
export const job_details = [
  {
    job_id: "001",
    job_title: "Senior Software Engineer (Windows Applications)",
    job_description:
      "We are looking for a .Net Developer with having a good knowledge of .Net, C#, WPF to join the Software Development team who possesses a passion for next generation technologies.",
    job_responsibilities: [
      "Ability to communicate in a precise and effective manner (verbal and written in English).",
      "Use professional concepts and company policies and procedures to solve a wide range of complex problems in creative and effective ways.",
      "Willingness to learn and accept new challenges in Software Development.",
      "Ability to reference and understand system and application documentation without assistance from others",
      "Ability to work independently.",
      "Communicate with the Team lead or colleagues for queries/clarification.",
      "Being a team player that can deliver in high pressure and high demanding environment.",
      "Ensure the code developed meets the requirements.",
      "Develop and deliver quality code adhering to the standards and guidelines.",
      "Good to have knowledge of Design Patterns, OOD principles and methods.",
      "Must be able to design custom control and complex user interface using WPF.",
      "Participate in application design sessions and provide valuable insights.",
      "Should have good knowledge of project life cycles, large project experience.",
      "Component Coding/Unit Testing/Debugging.",
      "Understanding of various SDLC processes including Agile Development and Waterfall.",
      "Must be able to assist in design and development reviews."
    ],
    required_skill:["C#.Net", "WPF", "MVVM"],
    technical_skills:[
      "Atleast 2+ years’ experience in windows app development.",
      "Object-Oriented Programming (OOP).",
      "Very good knowledge of the .Net Framework and the Core Libraries.",
      "Experience with Visual Studio 2017/2018/2019.",
      "Experience in C#, WPF.",
      "Experience in design complex UI in WPF, WinForms.",
      "Experience in WCF, Windows Services, Web Services.",
      "Design patterns, MVVM Presentation pattern.",
      "Good to have knowledge of SQL for MySQL, Complex Query, Stored Procedures, Triggers.",
      "Good to have Knowledge of Test-Driven Development (TDD) using NUnit."
    ],

    job_education: [
      "BE/ BTech in Computer Science or Engineering preferred."
    ],
    job_experience: [
      "2-4 years"
    ],
  },
  {
    job_id: "002",
    job_title: "Software Engineer (Android)",
    job_description:
      "We are looking for a Mobile Application Developer who possesses a passion for mobile technologies and will work with next generation technologies.",
    job_responsibilities: 
      [
        "Collaborate with peers and deliver on technical design, work estimation and implementation of new mobile applications and features including visualization aspects and server interactions & contracts.",
        "Develop and maintain our ever-growing code base that supports the Android Application.",
        "Prototype new innovative features with the newest APIs and SDKs on Android platform.",
        "Participate in code reviews and contribute to raising the quality of our product"
      ],
    required_skill:["Java / kotlin"],
    technical_skills:[
      "Good Knowledge in Object Oriented Design, Kotlin/Java and the Android Platform.",
      "Good knowledge of tools/libraries like; Gradle, Dagger, Espresso, JUnit, RxJava, okhttp",
      "A passion for simple, fast and clever UI.",
      "Ability to lead other engineers on disparate projects.",
      "Take ownership and pride in your features from inception to product delivery."
    ],
    job_education: ["BE/ BTech in Computer Science or Engineering preferred."],
    job_experience: ["0-2 years"],
  },
  {
    job_id: "003",
    job_title: "Software Engineer (AI / ML)",
    job_description:
      "We are looking for an AI/ML developer with having a good knowledge of C++ to join the Software Development team in our organization. A candidate must have experience in designing and implementing Machine Learning algorithms. Knowledge of Python is required.",
    job_responsibilities: [
      "Design, build and maintain efficient, reusable, and reliable C++ code.",
      "Perform active research using Python to create AI models.",
      "Masters/Bachelors with a focus on machine learning (deep learning) or image processing/computer vision.",
      "Proficiency in Python and/or C/C++.",
      "Familiarity with various deep learning techniques and libraries, and other scientific libraries."
    ],
    required_skill:["C++", "Python", "OpenCV"],
    technical_skills:[
      "Object-Oriented Programming (OOP).",
      "Image and video processing using OpenCV programming.",
      "Knowledge of AI/ML.",
      "Knowledge of neural networks is a plus.",
      "Strong proficiency in C++, with a fair knowledge of the language specification",
      "Thorough knowledge of the standard library, STL containers, and algorithms.",
      "Good understanding of memory management in non-garbage collected environments.",
      "Understanding dynamic polymorphism and C++ specific notions, such as friend classes.",
      "Familiarity with templating in C++.",
      "Knowledge of the latest C++14 standard is appreciated.",
      "Good to have knowledge of SQL for MySQL, Complex Query, Stored Procedures, Triggers."

    ],
    job_education: ["BE/ BTech in Computer Science or Engineering preferred."],
    job_experience: ["0-2 years"],
  },
  {
    job_id: "004",
    job_title: "Software Engineer (iOS)",
    job_description:
      "We are looking for an iOS/OSX developer who possesses a passion for mobile technologies having a good knowledge of Swift, ObjC, and cocoa pods to join the Software Development team in our organization.",
    job_responsibilities: [
      "Ability to communicate in a precise and effective manner (verbal and written in English).",
      "Use professional concepts and company policies and procedures to solve a wide range of complex problems in creative and effective ways.",
      "Willingness to learn and accept new challenges in Software Development.",
      "Ability to reference and understand system and application documentation without assistance from others.",
      "Ability to work independently.",
      "Communicate with the Team lead or colleagues for queries/clarification.",
      "Being a team player that can deliver in high pressure and high demanding environment.",
      "Ensure the code developed meets the requirements.",
      "Develop and deliver quality code adhering to the standards and guidelines.",
      "Good to have knowledge of Design Patterns, OOD principles and methods.",
      "Must be able to design custom control and complex user interface using WPF.",
      "Participate in application design sessions and provide valuable insights.",
      "Should have good knowledge of project life cycles, large project experience.",
      "Component Coding/Unit Testing/Debugging.",
      "Understanding of various SDLC processes including Agile Development and Waterfall.",
      "Must be able to assist in design and development reviews."
    ],
    required_skill:["ObjC", "iOS", "iPadOS", "XCode", "CocoaPods"],
    technical_skills:[
      "Good knowledge in Object-Oriented Programming (OOP).",
      "Experience with Objective-C, C/C++ and Swift and native iOS Development.",
      "Well versed with the internals of build and packaging tools like Cocoapods, Carthage.",
      "Experience using and maintaining CI/CD pipelines using XCode.",
      "Inclination towards writing tests in Xcode, following TDD",
      "Understanding of MVVM, MVP, MVC, Viper models and the ability to choose based on situations"
    ],
    job_education: ["BE/ BTech in Computer Science or Engineering preferred."],
    job_experience: ["0-2 years"],
  },
];
export const location_details = [
  {
    country: "India",
    city: "Bangalore Office",
    address:
      "Wework Vaishnavi Signature No. 78/9, Outer Ring Road, Bellandur Village Varthur Hobli Bengaluru East Ground Floor, Bengaluru, Karnataka 560103",
  },
  {
    country: "Canada",
    city: "Montreal Office",
    address: "1255 Rue du Fort, Montreal, Quebec, H3H 2B8",
  },
  {
    country: "USA",
    city: "Delaware Office",
    address: "3911 Concord Pike #8030 SMB 13988, Wilmington, Delaware, 19803",
  },
];
export const team_details = [
  {
    member_image: { members },
    member_name: "Prashanth Venkataswamy",
    linkedIn: "https://www.linkedin.com/in/prashanthvswamy",
    member_description:
      "A passionate engineering professional specialized in Signal processing and Machine learning. Over 10 years-experience in image processing and computer vision. Pursuing PhD in AI and an ardent believer of the fact that AI can change the world for the benefit of mankind.",
  },
  {
    member_image: { members },
    member_name: "Krishna Kumar",
    linkedIn: "https://www.linkedin.com/in/krishnakumarputhukara",
    member_description:
      "An entrepreneur with over 19 years of professional experience in the technology sector, with a focus on consumer internet, mobility and digital learning. Co-founded Wizroots Technologies which helps organizations to accelerate ‘Digital Transformation’ using disruptive technologies in Web, Mobile, Analytics and Cloud.",
  },
  {
    member_image: { members },
    member_name: "Ganesh Banda",
    linkedIn: "https://www.linkedin.com/in/blganesh",
    member_description:
      "Entrepreneur with over 14 years of experience in the field of embedded devices and operating systems. University book author in computer networks and a PhD scholar in Business Administration in the field of Business Strategies specialized in Artificial Intelligence and Autonomous Vehicles.",
  },
];
