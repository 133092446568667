import HeroImage from "./HeroImage";
import HeroText from "./HeroText";
import Controls from "./Controls";
import { useEffect, useRef } from "react";
const Hero = (props) => {
  const timeoutRef = useRef(null);
  // const progressBar = document.querySelector(".horizontal-bar");
  const prevClickHandler = () => {
    let proBar = document.querySelector(".hero-text-wrapper .progress");
    // console.log(props.counter,"prev");
    proBar.style.animationName = "none";

    if (props.counter === 0) {
      props.setCounter(2);
    } else {
      props.setCounter((prev) => prev - 1);
    }
    requestAnimationFrame(() => {
      proBar.style.animationName = "progressBar";
    });
  };
  const nextClickHandler = () => {
    let proBar = document.querySelector(".hero-text-wrapper .progress");
    // console.log(props.counter,"next");
    proBar.style.animationName = "none";

    if (props.counter === 2) {
      props.setCounter(0);
    } else {
      props.setCounter((prev) => prev + 1);
    }

    requestAnimationFrame(() => {
      proBar.style.animationName = "progressBar";
    });
  };
  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      nextClickHandler();
    }, 10000);
  }, [props.counter]);

  return (
    <>
      <div className="hero row">
        <div className="hero-text-wrapper col-md-6 ">
          <Controls
            prevClickHandler={prevClickHandler}
            nextClickHandler={nextClickHandler}
          />
          <HeroText
            counter={props.counter}
            // setCounter={props.setCounter}
          />
        </div>
        <HeroImage counter={props.counter} className="col-md-6 "/>
      </div>
      {/* -------------Hero section mobile view ---------------------
      <div className="hero hero-mobile">
        <Controls
          prevClickHandler={prevClickHandler}
          nextClickHandler={nextClickHandler}
        />
        <HeroImage counter={props.counter} />
        <HeroText counter={props.counter} />
      </div> */}
    </>
  );
};
export default Hero;
