import eyeicon from "../assets/images/eye-icon.svg";
import rightArrow from "../assets/images/right-arrow.svg";
import { useNavigate } from "react-router";
const Card = (props) => {
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate(props.data.title);
  };

  return (
    <div className="card col-md-8 col-xl-8 col-sm-8" onClick={clickHandler}>
      <span>
        <div className="eye-icon">
          <img src={props.data.logo} alt=""></img>
        </div>
        <div className="card-title heading-2">{props.data.title}</div>
        <p className="card-description">{props.data.description}</p>
      </span>

      {/* <div className="card-link-text text">
        {props.data.link_text}
        <img src={rightArrow} className="arrow" alt="View More"></img>
      </div> */}
    </div>
  );
};
export default Card;
