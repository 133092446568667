import React, { useState } from "react";
import { ReactComponent as PlusIcon } from "../assets/images/Icon plus.svg";
import { ReactComponent as MinusIcon } from "../assets/images/Icon minus.svg";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import { useNavigate } from "react-router";
import { LazyMotion } from "framer-motion";

// import faceRecImg from '../assets/images/capabilities/facedetection.svg'

const Accordion = ({ accordionData, capabilities}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const onApplyNow = (jobTitle, jobId) => {
    navigate(`/career/apply-now/${jobId}/${jobTitle}`);
  };
  const onMoreDetails = (jobTitle, jobId) => {
    navigate(`/career/more-details/${jobId}/${jobTitle}`);
  };
  const toggleAccordion = (index) => {
    if (activeIndex === index) setActiveIndex(null);
    else setActiveIndex(index);
  };
  const accordionClick = (index) => {
    toggleAccordion(index);
  };

  return (
    <>
      
      {
        capabilities?
        <div className="capability-wrap">
        {
          accordionData.map(ob=>{
            // console.log(ob);
            return(

            <div className="box">
              <div className="imgsec">
                <img src={ob.img} alt="" />
              </div>
              <div className="accordion-border"></div>
              <div className="title">
                {ob.title}
              </div>
            </div>
            )
          })
        }
      </div>
      :
      <>
      {accordionData.map((ele, index) => (
        <div
          className="accordion-wrapper"
          key={index}
          onClick={() => {
            accordionClick(index);
          }}
        >
          <div
            className={`accordion-title ${activeIndex === index ? "open" : ""}`}
          >
            {ele.title}
            <span className="accordion-icon">
              {activeIndex === index ? <MinusIcon /> : <PlusIcon />}
            </span>
          </div>
          <div className="accordion-border"></div>
          <div
            className={`accordion-item ${
              activeIndex !== index ? "collapsed" : ""
            }`}
          >
            <div className="accordion-content text">{ele.children}</div>
            {ele.buttons ? (
              <div className="accordian-button">
                <PrimaryButton
                  className="button"
                  onClick={() => onApplyNow(ele.title, ele.id)}
                >
                  {ele.buttons[0]}
                </PrimaryButton>
                <SecondaryButton
                  className="button"
                  onClick={() => onMoreDetails(ele.title, ele.id)}
                >
                  {ele.buttons[1]}
                </SecondaryButton>
              </div>
            ) : null}
          </div>
        </div>
      ))}
      </>
        
      }
      
    </>
  );
};
export default Accordion;
