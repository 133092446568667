import React, { useState } from "react";
import Eyebrow from "../Components/Eyebrow";
import Heading2 from "./Heading-2";
import { accordion_data } from "../data";
import Accordion from "./Accordion";
import { ReactComponent as HandImage } from "../assets/images/sevvv.svg";
const Services = () => {
  const [isOpen, setOpen] = useState(false);
  const [accordionIndex, setIndex] = useState(0);
  const toggleAccordion = (index) => {
    setOpen(!isOpen);
    setIndex(index);
  };

  window.onload = function () {
    var icon1 = document.querySelector(".icon1");
    var icon2 = document.querySelector(".icon2");
    var icon3 = document.querySelector(".icon3");
    var icon4 = document.querySelector(".icon4");
    var icon5 = document.querySelector(".icon5");
    icon1.addEventListener("click", (e) => changeIcon(e, icon1));
    icon2.addEventListener("click", (e) => changeIcon(e, icon2));
    icon3.addEventListener("click", (e) => changeIcon(e, icon3));
    icon4.addEventListener("click", (e) => changeIcon(e, icon4));
    icon5.addEventListener("click", (e) => changeIcon(e, icon5));
  };
  const changeIcon = (e, icon) => {
    var centerIcon = document.querySelector(".bigIcon");
    centerIcon.insertAdjacentElement("afterend", centerIcon.childNodes[0]);
    centerIcon.appendChild(icon);
    icon.setAttribute("transform", "translate(0,120)");

  };

  return (
    <div className="services">
      <Eyebrow>Capabilities</Eyebrow>
      <Heading2>
        What our
        <span className="aqua-color"> SDK</span>
        <br />
        offers
      </Heading2>
      <div className="service-content row">
        <div className="accordion-div col-md-6">
          <Accordion capabilities={true} accordionData={accordion_data} />
        </div>

        <div className="accordion-img col-md-6">
          <div className="class-1"> <div className="image-container mech-hand-container"></div>
          <HandImage className="foreground-image tools" /></div>
        </div>
      </div>
    </div>
  );
};
export default Services;
