import Eyebrow from "./Eyebrow";
import Heading2 from "./Heading-2";
import { device_data } from "../data";
import Cards from "./Cards";
const Device = () => {
  return (
    <div className="device">
      <Eyebrow>Products</Eyebrow>
      <Heading2>
        <span className="aqua-color">Carefully crafted</span>
        <br />
        Futuristic Products
      </Heading2>
      <Cards data={device_data} rtl="rtl" />
    </div>
  );
};
export default Device;
