import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import Fist from "../assets/images/Asset 3.png";
import Eyebrow from "../Components/Eyebrow";
import FormContent from "../Components/FormContent";
import Heading1 from "../Components/Heading-1";
import Heading2 from "../Components/Heading-2";
import SocialMedia from "../Components/SocialMedia";
import { certificates } from "../data";
import blueWave from "../assets/images/asdasdasd.png";
import Grid from "../assets/images/Repeat Grid 7.svg";

const ContactUsPage = () => {
  return (
    <div className="contact-us-page container">
      <div className="contact-us-page_hero">
        <div className="contact-us-page_hero-left">
          <Eyebrow>Contact Us</Eyebrow>
          <Heading2>
            We would <span className="aqua-color">love</span>
            <br />
            to hear from you
          </Heading2>
          <h5 className="contact-us-page_hero-left-text light-grey-color ">
          We are an Enterprise AI Company. We are a team of experienced software and hardware geeks, designers, innovators doing passionate things to run your business and keep live your dreams. The goal is to provide innovative solutions to everyone by leveraging the benefits that AI, Deep learning, and Internet of Things paradigm has to offer. Neoastra’s flagship solutions offer the power of AI and Machine Learning to every problem that businesses and customers face in their operations. 
          <br/>At Neoastra, we couple augmented intelligence with image analytics, video analytics, and emerging technologies with IOT to help businesses deliver immersive customer experiences and outperform the competition.
          </h5>
        </div>
        <div className="class-1">
          <div className="contact-us-page_hero-right image-container"></div>
          <img src={Fist} className="foreground-image hand" alt="" />
        </div>
      </div>

      <div className="contact-us-page-location">
        <img src={Grid} className="grid" />
        <Eyebrow>Media & recognition</Eyebrow>
        <Heading2>
        We are recognized and covered <br/> by leading media houses.
        </Heading2>
        <div className="contact-us-page-location-list-mobile row">
          <Swiper
            dir="rtl"
            slidesPerView={"auto"}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            className="mySwiper"
          >
            {/* {location_details.map((location, index) => (
              <SwiperSlide key={index}>
                <div className="location-card col-md-4 col-sm-12">
                  <div className="location-card-content ">
                    <div className="location-card-content_country">
                      {location.country}
                    </div>
                    <div className="location-card-content_city">
                      {location.city}
                    </div>
                    <div className="location-card-content_address">
                      {location.address}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))} */}
            {certificates.map((cert,i)=>{
              return(
                <SwiperSlide key={i}>
                  <div className="cert-card">
                    <img src={cert} alt="" />
                  </div>
                </SwiperSlide>

              )
            })}
          </Swiper>
        </div>
        <div className="contact-us-page-location-list-desktop row">
          {certificates.map((cert, index) => (
            <div className="cert-card location-card col-md-4 col-sm-12" key={index}>
              <img src={cert} alt="" />
            </div>
          ))}
        </div>
      </div>
      <div className="row">
        <img src={blueWave} className="blue-wave" />
      </div>
      <div className="contact-us-page-reach">
        <div className="contact-us-page-reach-box">
          <div className="contact-us-page-reach-box-content">
            <div className="contact-us-page-reach-box-content_left">
              <Eyebrow>Contact</Eyebrow>
              <Heading2>
                Feel free to <br /> <span className="aqua-color">Message</span>{" "}
                us
              </Heading2>
              <h5 className="contact-us-page-reach-box-content_left-text light-grey-color  ">
              We’d love to hear from you <br/>
              Have questions about our products, features, trials, or pricing? Need a demo?
              <br/>
              Our teams will help you. Let's work together to
              meet any business needs with our AI products and Solutions
              </h5>
              <SocialMedia />
            </div>
            <div className="contact-us-page-reach-box-content_right">
              <FormContent formTitle="Write to us" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUsPage;
