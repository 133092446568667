import ProductPage from "../Components/ProductPage";
import { neovision_page_data } from "../data";

const NeoVisionPage = () => {
  return (
    <ProductPage
      data={neovision_page_data}
      image={neovision_page_data[0].hero_image.NeovisionFace}
      second_image={neovision_page_data[0].second_image.NeovisionSecondImage}
    />
  );
};
export default NeoVisionPage;
