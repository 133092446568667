import { Routes, Route, Navigate } from "react-router-dom";
import { motion } from "framer-motion";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import AboutUs from "../Pages/AboutUs";
import Espirit from "../Pages/Espirit";
import HomePage from "../Pages/Homepage";
import NeoLivingPage from "../Pages/Neoliving";
import NeoVisionPage from "../Pages/Neovision";
import Career from "../Pages/Career";
import JobDescriptionPage from "../Pages/JobDescriptionPage";
import ContactUsPage from "../Pages/ContactUsPage";
import AIEdge from "../Pages/AIEdge";
import AIOnPremise from "../Pages/AIOn-Premise";
import AIArm from "../Pages/AIArm";
const RouteLinks = (props) => {
  return (
    <>
      <Header
        toggleShowMenu={props.toggleShowMenu}
        isVisible={props.isVisible}
      />
      <Routes>
        <Route
          path="/"
          element={
            <HomePage
              toggleShowMenu={props.toggleShowMenu}
              isVisible={props.isVisible}
            />
          }
        ></Route>
        <Route path="/neovision" element={<NeoVisionPage />}></Route>
        <Route path="/neoliving" element={<NeoLivingPage />}></Route>
        <Route path="/espirit" element={<Espirit />}></Route>
        <Route path="/AI-Edge" element={<AIEdge />}></Route>
        <Route path="/AI-On-premise" element={<AIOnPremise />}></Route>
        <Route path="/AI-Arm" element={<AIArm />}></Route>
        <Route path="/contact-us" element={<ContactUsPage />}></Route>
        <Route path="/career" element={<Career />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route
          path="/career/apply-now/:jobId/:jobTitle"
          element={<JobDescriptionPage />}
        ></Route>
        <Route
          path="/career/more-details/:jobId/:jobTitle"
          element={<JobDescriptionPage />}
        ></Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};
export default RouteLinks;
