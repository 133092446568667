import ProductHero from "./ProductHero";
const ProductPage = (props) => {
  return (
    <div className="product-page container">
      <ProductHero
        data={props.data[0]}
        image={props.image}
        second_image={props.second_image}
      />
    </div>
  );
};
export default ProductPage;
