const SecondaryButton = (props) => {
  return (
    <button
      className={`secondary-button ${props.className}`}
      onClick={props.onClick}
      type={props.type}
    >
      {props.children}
    </button>
  );
};
export default SecondaryButton;
