import About from "./About";
import Eyebrow from "./Eyebrow";
import FeatureCard from "./FeatureCard";
import Heading1 from "./Heading-1";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import Grid from "../assets/images/Repeat Grid 4.svg";
import { about_us_page } from "../data";
import Heading2 from "./Heading-2";

const ProductHero = (props) => {
  return (
    <>
      <div className="product-hero container">
        <div className="product-hero_container">
          <div className="product-hero_right-content product-hero_image-container class-1">
            <div className="image-container"></div>
            <img
              className="foreground-image face-1 pd-land-img"
              src={props.image}
              alt={props.data.hero_title}
            />
          </div>
          <div className="product-hero_left-content">
            <Heading1>
              <span className="aqua-color">
                {props.data.hero_title.slice(0, 3)}
              </span>
              {props.data.hero_title.slice(3)}
            </Heading1>
            <h5 className="product-hero-text light-grey-color">
              {props.data.hero_text}
            </h5>
            <div className="product-hero-buttons">
              <PrimaryButton className="button">DISCOVER MORE</PrimaryButton>
              <SecondaryButton className="button">CONTACT US</SecondaryButton>
            </div>
          </div>
        </div>
        <img src={Grid} className="grid-1" alt="" />
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Heading1>
            About
            <span className="aqua-color"> {props.data.hero_title}</span>
          </Heading1>
        </div>
        <div className="product-hero_about-section">
          <img src={props.second_image} alt={props.data.hero_title} />

          <h5
            className="product_about-text"
            dangerouslySetInnerHTML={props.data.about_text}
          ></h5>
        </div>
        <div className="w100 p-rel">
          <div>
            <Eyebrow>{props.data.eyebrow_text}</Eyebrow>
            <div
              dangerouslySetInnerHTML={props.data.eyebrow_title}
              className="heading-2 w-25perc"
            ></div>
          </div>
          <img src={Grid} className="grid-2" alt="" />
          <div className="product-feature_list row">
            {props.data.feature_list.map((feature, index) => (
              <FeatureCard feature={feature} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="about-section">
        <div className="">
          <About
            about_eyebrow={about_us_page.about_eyebrow_variation2}
            about_title={about_us_page.about_title}
            about_text={about_us_page.about_text}
            about_footer={about_us_page.about_footer}
          />
        </div>
      </div>
    </>
  );
};
export default ProductHero;
