import React from 'react'
import { useState } from 'react'
import PrimaryButton from "../Components/PrimaryButton";

function Careerform() {
    const [filename,setFilename] = useState("Choose File ...")
    const filehand = (f)=>{
        setFilename(f.name)
    }
  return (
    <form>
        <div>
            <span>
                <label htmlFor="fullname">Fullname</label>
                <input className='input' type="text" name="fullname" />
            </span>
            <span>
                <label htmlFor="phone">Phone Number</label>
                <input className='input' type="number" name="phone" />
            </span>
        </div>
        <div>
            <span>
                <label htmlFor="email">Fullname</label>
                <input className='input' type="email" name="email" />
            </span>
            <span>
                <label>Resume</label>
                <label className="input file-con" htmlFor='file'>
                    <input className='file' type="file" id='file' name="resume" onChange={e=>{filehand(e.target.files[0])}} />
                    {filename}
                </label>
            </span>
        </div>
        <div className="submit">
            <PrimaryButton className="submitBtn">Submit</PrimaryButton>
        </div>
    </form>
  )
}

export default Careerform