const EnquiriesComponent = () => {
  const enquiryHandler = () => {
    window.location.href = "mailto:info@neoastra.com";
  };
  return (
    <div className="enquiry">
      <p>Enquiries</p>
      <div
        className="text"
        style={{ opacity: "1", cursor: "pointer" }}
        onClick={enquiryHandler}
      >
        info@neoastra.com
      </div>
    </div>
  );
};

export default EnquiriesComponent;
