import { useEffect, useState } from "react";
import "./assets/style.scss";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop";
import Menu from "./Pages/Menu";
import NavMenu from "./Components/NavMenu";
import RouteLinks from "./Routes/RouteLinks";

const App = () => {
  const [menuVisible, setmenuVisible] = useState(false);
  const showMenuHandler = () => {
    setmenuVisible(!menuVisible);
  };
  const hideMenuHandler = () => {
    setmenuVisible(false);
  };
  return (
    <>
      <div className="">
        <ScrollToTop />
        <NavMenu toggleShowMenu={showMenuHandler} isVisible={menuVisible} />
        <RouteLinks toggleShowMenu={showMenuHandler} isVisible={menuVisible} />
      </div>
      <Footer toggleShowMenu={showMenuHandler} isVisible={menuVisible} />
    </>
  );
};
export default App;
