import { useState } from "react";
import Process from "./Process";
import Scale from "./Scale";
const ProcessComponent = () => {
  const [processCounter, setProcessCounter] = useState(0);
  return (
    <div className="process-component">
      <Process counter={processCounter} setCounter={setProcessCounter} />
      <Scale processCounter={processCounter} />
    </div>
  );
};
export default ProcessComponent;
