import Title from "../Components/Title";
import crossIcon from "../assets/images/cross.svg";
import { menu_data } from "../data";
import brainIcon from "../assets/images/brainIcon.png";
import PhoneComponent from "../Components/PhoneComponent";
import EnquiriesComponent from "../Components/EnquiriesComponent";
import SocialMedia from "../Components/SocialMedia";
import { motion } from "framer-motion";
import { Link, Navigate, useNavigate } from "react-router-dom";
import LeftIcon from "../assets/images/chevron-left.svg";

const onIconClick = (indexNum) => {
  document
    .querySelectorAll(".child-list")
    .forEach((l) => (l.style.display = "none"));
  document
    .querySelectorAll(".link img")
    .forEach((l) => (l.style.rotate = "0deg"));

  document.querySelectorAll(".link img").forEach((l, index) => {
    if (index === indexNum) {
      l.classList.remove("selected");
    }
  });

  const link = document.getElementsByClassName("link")[indexNum];
  if (link.querySelector("img").classList.contains("selected")) {
    link.querySelector("img").classList.remove("selected");
    link.querySelector(".child-list").style.display = "none";
  } else {
    link.querySelector("img").classList.add("selected");
    link.querySelector(".child-list").style.display = "flex";
  }
};

const NavMenu = (props) => {
  const navigate = useNavigate();
  const onCrossClickHandler = (link) => {
    props.toggleShowMenu();
    document.querySelectorAll(".link img").forEach((l) => {
      l.classList.remove("selected");
    });
    document
      .querySelectorAll(".child-list")
      .forEach((l) => (l.style.display = "none"));

    navigate(`/${link}`);
  };
  const variants = {
    open: {
      display: "block",
      y: 0,
      transition: {
        type: "spring",
        stiffness: 20,
        duration: 0.5,
      },
    },
    closed: {
      display: "none",
      y: "-140%",
      transition: {
        type: "spring",
        stiffness: 20,
        duration: 2,
        display: {
          delay: 1,
        },
      },
    },
  };

  return (
    <motion.div
      initial={"closed"}
      exit="closed"
      animate={props.isVisible ? "open" : "closed"}
      variants={variants}
      className="menu-wrapper"
    >
      <div className=" nav-menu-wrapper container">
        <div className="row nav-menu">
          <div className="nav-header col-md-12">
            <img src={crossIcon} alt="" onClick={onCrossClickHandler} className="closeIcon" />
            <Title />
            <div className="dummyDiv"></div>
          </div>
        </div>
        <div className="row nav-content">
          <div className="col-md-6 col-lg-6 col-xs-12 menu-left">
            <div className="menu-content">
              <div className="verticle-line"></div>
              {menu_data.map((d, index) => (
                <div key={index} className="link">
                  <Link
                    to={`${d.link}`}
                    onClick={() => {
                      d.icon ? onIconClick(index) : onCrossClickHandler();
                    }}
                    className="nav-link-text"
                  >
                    {d.name} <span>{d.icon ? <img src={LeftIcon} /> : ""}</span>
                  </Link>
                  {d.children ? (
                    <div className="child-list">
                      {d.children.map((child, index) => (
                        <div
                          className="list-element"
                          key={index}
                          onClick={() => {
                            onCrossClickHandler(child.link);
                          }}
                        >
                          {child.face_text}
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
            <div className="mobile-social">
              <SocialMedia head={true} />
            </div>
          </div>
          <div className="col-md-6 col-lg-6 ">
            <div className="nav-menu-content-right">
              <div className="nav-menu-brain-image-wrapper">
                <img
                  className="nav-menu-brain-image"
                  src={brainIcon}
                  alt=""
                ></img>
              </div>
              <div className="nav-menu-content-right__footer">
                <PhoneComponent />
                <EnquiriesComponent />
                <div className="desktop-social">
                  <SocialMedia navMenu={true} head={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default NavMenu;
