import { useEffect, useState } from "react";
import Eyebrow from "./Eyebrow";
import PrimaryButton from "./PrimaryButton";
const FormContent = (props) => {
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [message, setMessage] = useState();
  const [formValid, setFormValid] = useState(false);
  useEffect(() => {
    setFormValid(() => !!fname && !!lname && !!emailId & !!message);
  }, [fname, lname, emailId, message]);
  const fnameChangeHandler = (e) => {
    setFName(e.target.value);
  };
  const lnameChangeHandler = (e) => {
    setLName(e.target.value);
  };
  const emailChangeHandler = (e) => {
    setEmailId(e.target.value);
  };
  const messageChangeHandler = (e) => {
    setMessage(e.target.value);
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    alert("Applied Successfully!!!");
    setFName("");
    setLName("");
    setEmailId("");
    setMessage("");
  };
  return (
    <form className="apply-now-form" onSubmit={onFormSubmit}>
      <Eyebrow>{props.formTitle}</Eyebrow>
      <div className="apply-now-form-inputs">
        <div className="row">
          <div className="apply-now-form-input ">
            <input
              className="apply-now-form-input-input light-grey-color"
              placeholder="Enter your first name "
              type="text"
              required
              name="name"
              onChange={fnameChangeHandler}
              value={fname}
            />
          </div>
          <div className="apply-now-form-input">
            <input
              placeholder="Enter your last name"
              className="apply-now-form-input-input light-grey-color"
              type="text"
              name="last-name"
              onChange={lnameChangeHandler}
              value={lname}
            />
          </div>
        </div>
        <div className="row">
          <div className="apply-now-form-input email-input">
            <input
              className="apply-now-form-input-input light-grey-color"
              type="email"
              required
              name="email"
              onChange={emailChangeHandler}
              value={emailId}
              placeholder="Enter your email address"
            />
          </div>
        </div>
        <div className="row">
          <div className="apply-now-form-input message-input">
            <textarea
              className="apply-now-form-input-input light-grey-color"
              placeholder="Enter your message"
              type="text"
              onChange={messageChangeHandler}
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="apply-now-form-input">
            <label>Resume</label>
            <input
              required
              type="file"
              name="resume"
              onChange={fileChangeHandler}
              className="apply-now-form-input-attachfile"
            />
          </div>
        </div> */}
      </div>
      <div className="apply-now-form-controls">
        <PrimaryButton className="button" type="submit" disabled={!formValid}>
          Submit
        </PrimaryButton>
      </div>
    </form>
  );
};
export default FormContent;
