import Header from "../Components/Header";
import Products from "../Components/Products";
import Services from "../Components/Services";
import AreasOfExpertise from "../Components/AreasOfExpertise";
import Device from "../Components/Device";
import ProcessComponent from "../Components/ProcessComponent";
import HeroComponent from "../Components/HeroComponent";
import About from "../Components/About";
import Footer from "../Components/Footer";
import whiteWave from "../assets/images/asdasdasd.png";
import whiteWavemob from "../assets/images/asdasdasd.png";
import grid8 from "../assets/images/RepeatGrid8.svg";
import grid4 from "../assets/images/Repeat Grid 4.svg";
import grid5 from "../assets/images/Repeat Grid 5.svg";
import grid7 from "../assets/images/Repeat Grid 7.svg";
import { motion } from "framer-motion";
import { useState } from "react";
import { about_us_page } from "../data";
import SupportedBy from "../Components/SupportedBy";

const HomePage = (props) => {
  return (
    <motion.div
      animate={{ position: props.isVisible ? "fixed" : "unset" }}
      className="homeContainer"
    >
      <div className="exep-parterswrap container">
        <div>
          <HeroComponent />
          <div className="grid-4">
            <img
              src={grid4}
              className="grid-4-desktop grid-4-bg-img"
              alt=""
            ></img>
            <Products />
          </div>
          <div className="grid-5">
            <img src={grid5} className="grid-5-desktop grid-5-bg-img"></img>
            <Device />
          </div>
          <div className="grid-7">
            <Services />
            <div className="grid7-5 mt-mob60rem-d0rem">
              <img src={grid7} className="grid-7-desktop grid-7-bg-img"></img>
              <AreasOfExpertise />
            </div>
          </div>
          <div className="grid-8">
            <img src={grid8} className="grid-8-desktop grid-8-bg-img"></img>
            <ProcessComponent />
          </div>
        </div>
      </div>
    

      <div className="about-us">
        <About
          about_eyebrow={about_us_page.about_eyebrow}
          about_title={about_us_page.about_title}
          about_text={about_us_page.about_text}
        />
      </div>

      <div className="gradient-background container">
        <SupportedBy />
      </div>
      {/* <Footer /> */}
    </motion.div>
  );
};
export default HomePage;
