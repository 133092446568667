import Eyebrow from "./Eyebrow";
import Heading2 from "./Heading-2";
import { process_data } from "../data";
import Controls from "./Controls";
import rightArrow from "../assets/images/right-arrow.svg";
import eye from "../assets/images/eye-icon2.svg";
import { useEffect, useRef } from "react";
import Video from "./YoutubeEmbed";
const Process = (props) => {
  const timeoutRef = useRef(null);
  const progressBar = document.querySelector(".horizontal-bar");
  const prevClickHandler = () => {
    let proBar = document.querySelector(".process-component .progress");
    proBar.style.animationName = "none";

    if (props.counter === 0) {
      props.setCounter(6);
    } else {
      props.setCounter((prev) => prev - 1);
    }
    requestAnimationFrame(() => {
      proBar.style.animationName = "progressBar";
    });
  };
  const nextClickHandler = () => {
    let proBar = document.querySelector(".process-component .progress");
    proBar.style.animationName = "none";

    if (props.counter === 6) {
      props.setCounter(0);
    } else {
      props.setCounter((prev) => prev + 1);
    }

    requestAnimationFrame(() => {
      proBar.style.animationName = "progressBar";
    });
  };
  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      nextClickHandler();
    }, 10000);
  }, [props.counter]);

  return (
    <div className="process">
      <Eyebrow>Process</Eyebrow>
      <Heading2>
        How we <span className="aqua-color">do</span> it
      </Heading2>
      <div className="deskview-arrows">
        <Controls
                prevClickHandler={prevClickHandler}
                nextClickHandler={nextClickHandler}
              />
        </div>
      <div className="process-content row">
        <div className="process-text-wrap col-md-5">
          <span className="controls-wrap">
            <Controls
              prevClickHandler={prevClickHandler}
              nextClickHandler={nextClickHandler}
            />

          </span>
          <div className="process-text ">
            <div className="process-icon">
              <img src={eye} className="arrow2" alt=""></img>
            </div>
            <div className="process-text-content">
              <div>
                <div className="process-text-headingr">
                  {process_data[props.counter].title}
                </div>
                <h5 className="process-text-description opacity ">
                  {process_data[props.counter].description}
                </h5>
              </div>
              <div className="process-text-link">
                {process_data[props.counter].link_text}
                <div className="arrow">
                  <img src={rightArrow} className="arrow" alt="View More"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="process-video col-md-7">
          <Video counter={props.counter} resettimer={resetTimeout}/>
        </div>
      </div>
    </div>
  );
};
export default Process;
