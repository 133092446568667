import { useRef } from "react";
import { useParams } from "react-router-dom";
import ApplyNowForm from "../Components/ApplyNowForm";
import Heading1 from "../Components/Heading-1";
import Heading2 from "../Components/Heading-2";
import PrimaryButton from "../Components/PrimaryButton";
import { job_details } from "../data";
const JobDescriptionPage = () => {
  const params = useParams();
  const job = job_details.filter((j) => params.jobId === j.job_id);
  // console.log(job[0].job_responsibilities);
  const applyNowRef = useRef(null);
  const applyNowHandler = () => {
    applyNowRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="job-description container">
      <div className="job-description_heading">
        <h2>{job[0].job_title}</h2>
        <PrimaryButton className="button" onClick={applyNowHandler}>
          Apply now
        </PrimaryButton>
      </div>
      <div className="job-description_description">
        <div className="job-description-title">Job Description</div>
        <div className="job-description-content text">
          {job[0].job_description}
        </div>
      </div>
      <div className="job-description_responsibilities">
        <div className="job-description-title">Responsibilities</div>
        <div className="job-description-content text" >
          <ul>
            {job[0].job_responsibilities.map(r => {
              return (<li>{r}</li>)
            })}
          </ul>
        </div>
      </div>
      <div className="job-description_education">
        <div className="job-description-title">Required Skills</div>
        <div className="job-description-content text">
          <ul>
            {job[0].required_skill.map(r => {
              return (
                <li>{r}</li>
              )
            })}
          </ul>

        </div>
      </div>
      <div className="job-description_education">
        <div className="job-description-title">Technical Skills</div>
        <div className="job-description-content text">
          <ul>
            {job[0].technical_skills.map(r => {
              return (
                <li>{r}</li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="job-description_education">
        <div className="job-description-title">Education</div>
        <div className="job-description-content text">
          <ul>
            {job[0].job_education.map(r => {
              return (
                <li>{r}</li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="job-description_education">
        <div className="job-description-title">Experience</div>
        <div className="job-description-content text">
          <ul>
            {job[0].job_experience.map(r => {
              return (
                <li>{r}</li>
              )
            })}
          </ul>
        </div>
      </div>

      <div id="apply-job" className="job-description_apply-now-section p-rel" ref={applyNowRef}>
        <ApplyNowForm eyebrow_text="Apply for this position" />
      </div>
    </div>
  );
};
export default JobDescriptionPage;
