import FacebookIcon from "../assets/images/facebook.svg";
import InstagramIcon from "../assets/images/instagram.svg";
import LinkedinIcon from "../assets/images/linkedin.svg";
import NavMenu from "./NavMenu";
const SocialMedia = (props) => {
  // const navigateToFacebook = () => {
  //   window.location.href = "https://www.facebook.com/NeoastraT";
  // };
  // const navigateToLinkedIn = () => {
  //   window.location.href =
  //     "{props.linkedin}";
  // };
  return (
    <div className="social-media">
      <h5 style={{ display: props.head ? "block" : "none" }}>Social</h5>
        
        {
          !props.linkedin?
          <div className="social-media-images">
            <a href="https://linkedin.com/company/neoastra/">
              <img src={LinkedinIcon} alt="" />
            </a>
            <a href="https://www.facebook.com/NeoastraT/"><img src={FacebookIcon} alt="Facebook"/></a>
            <a href=""><img src={InstagramIcon} alt="" /></a>
          </div>
            :
            <div className="social-media-images">
              <a href={props.linkedin}>
                <img src={LinkedinIcon} alt="" />
              </a>
              
            </div>
        }

      {/* </div> */}
    </div>
  );
};
export default SocialMedia;
