import Eyebrow from "./Eyebrow";
import { useNavigate } from "react-router-dom";
import waweImg from '../assets/images/asdasdasd.png'

const About = (props) => {
  const naviate = useNavigate();
  return (
    <div className="about">
      <div className="about-content-wrap container">
      <div className="about-box">
        <div className="about-blur"></div>
        <div className="about-content">
          <Eyebrow>{props.about_eyebrow}</Eyebrow>
          <div
            dangerouslySetInnerHTML={props.about_title}
            className="heading-2"
          ></div>
          <h5
            className="about-text"
            dangerouslySetInnerHTML={props.about_text}
          ></h5>
          <div
            className="about_footer"
            onClick={() => naviate("/contact-us")}
          >
            {props.about_footer}
          </div>
        </div>
      </div>
      </div>
      
      <img src={waweImg} className="waweImg" />
    </div>
  );
};
export default About;
