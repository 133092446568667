import HamburgerIcon from "../assets/images/Group 99.svg";
import SocialMedia from "./SocialMedia";
import Title from "./Title";
import { motion } from "framer-motion";
import { useEffect } from "react";
const Header = (props) => {
  var sticky;
  var header;
  const onHamburgerClick = () => {
    props.toggleShowMenu();
  };
  const myFunction = () => {
    if (window.pageYOffset > sticky) {
      header.classList.add("sticky");
    } else {
      header.classList.remove("sticky");
    }
  };
  useEffect(() => {
    header = document.getElementById("myHeader");
    sticky = header.offsetTop;
    window.onscroll = function () {
      myFunction();
    };
  }, []);
  return (
    <motion.div
      id="myHeader"
      className="row container mg-0-auto"
      animate={{ position: props.isVisible ? "fixed" : "unset" }}
    >
      <div className="homepage-header">
        <div
          className="home-header_hamburger-container"
          onClick={onHamburgerClick}
        >
          <img src={HamburgerIcon} alt="" />
        </div>
        <Title />
        <SocialMedia head={false} />
      </div>
    </motion.div>
  );
};
export default Header;
